import React from "react";

// components

import Navbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footers/Footer"

import backgroundImage from "assets/img/office-cleaning.jpeg"

export default function Kontorsstadning() {
    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage:
                                `url(${backgroundImage})`,
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                                <h1 className="text-white font-semibold text-5xl">
                                    Få rutin på kontorsstädningen
                                </h1>
                                <p className="mt-4 text-lg text-blueGray-200">
                                    Klart att det är stressigt att hinna med att städa på kontoret, och dammråttor är inga trevliga kollegor. Vi hjälper er gärna med kontorsstädning, så att ni kan fokusera på det ni är bra på.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="pb-20 bg-white -mt-24">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                            <i className="fas fa-award"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Erfarna Städexperter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vårt team består av erfarna och pålitliga städexperter som är dedikerade till att leverera högkvalitativ service.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                                            <i className="fas fa-retweet"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Anpassade Tjänster</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi förstår att varje hem är unikt. Våra städare skräddarsyr tjänsterna efter dina specifika behov och önskemål.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                                            <i className="fas fa-fingerprint"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Miljövänliga Produkter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi strävar efter att vara miljövänliga. Våra städare använder endast högkvalitativa, miljövänliga rengöringsprodukter.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap items-center mt-32">
                            <div className="w-full px-4 mr-auto ml-auto">
                                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                    Vad ingår i kontorsstädning?
                                </h3>
                                <h4 className="text-xl mb-2 font-semibold leading-normal">
                                    Generella moment i alla rum:
                                </h4>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                    Vi dammtorkar alla tillgängliga ytor som kontorsplatser, bordslampor, armaturer, hyllor och fönsterbrädor. Dammsuger golv, lister, eluttag och mattor samt moppar alla hårda golv. Papperskorgar töms och fläckar avlägsnas från dörrar, glaspartier och whiteboards. Vi putsar speglar och ordnar stolar i mötesrum och på kontorsplatser, torkar av vid behov.
                                </p>
                                <h4 className="text-xl mb-2 font-semibold leading-normal">
                                    Kök:
                                </h4>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Dammtorkning och rengöring av alla öppna ytor samt borttagning av fläckar på köksluckor, bord, stolar och övriga vitvaror. Rengöring in- och utvändigt där sopbehållare finns, mikrovågsugn och kaffemaskin. Startar/tömmer diskmaskin eller diskar den befintliga disken. Påfyllning av handtvål, diskmedel och pappershanddukar. Byte av disktrasa och diskborste vid behov. Soporna töms och golvet dammsugs och moppas.
                                </p>
                                <h4 className="text-xl mb-2 font-semibold leading-normal">
                                    Badrum:
                                </h4>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Dammtorkning och rengöring av hängare, hållare och badrumsskåp. Rengöring av handfat, kran, toalett och eventuell dusch. Torkar av tvättmaskin och torktumlare, putsar spegeln och tömmer papperskorgarna. Påfyllning av handtvål, toapapper och pappershanddukar. Dammsugning och moppning av golvet.
                                </p>
                                <h4 className="text-xl mb-2 font-semibold leading-normal">
                                    Städmaterial:
                                </h4>

                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Vi tar med oss mopp, trasor och miljömärkta städmedel, som ingår i priset. För att fullständigt kunna utföra städningen behöver ni tillhandahålla dammsugare, dammvippa och städvagn/hinkar. Vid önskemål kan vi också hjälpa till med inköp av städmaterial. Förbrukningsmaterial som pappershanddukar, toapapper, soppåsar/papperskorgspåsar, maskindisktabletter, dammsugarpåsar och specialmedel kan ni också beställa från oss
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-wrap items-center mt-16">
                            <div className="w-full px-4 mr-auto ml-auto">
                                <h3 className="text-3xl mb-2 font-semibold leading-normal" id="lokalstadning">
                                    Lokalstädning
                                </h3>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                    Vi på Laki Städ erbjuder inte bara professionell städning för kontor utan sträcker oss även bortom arbetsplatserna. Våra tjänster inkluderar även städning av lokaler, butiker, restauranger och andra företagsmiljöer.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Oavsett om det är en kontorslokal, en butik eller en restaurang, kan du lita på oss för att skapa en ren och trivsam miljö för dina kunder och anställda.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Vår dedikerade personal är tränad för att möta de unika städbehoven som olika typer av företagslokaler kan ha. Vi strävar efter att leverera högkvalitativ städning och service oavsett företagets storlek eller verksamhetsområde. Kontakta oss idag för att diskutera hur vi kan anpassa våra tjänster för att passa just ditt företags behov.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
