import React from "react";

// components

import Navbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footers/Footer"

import backgroundImage from "assets/img/stairs-cleaning.jpeg"

export default function Trappstadning() {
    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage:
                                `url(${backgroundImage})`,
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                                <h1 className="text-white font-semibold text-5xl">
                                    Trappstädning
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="pb-20 bg-white -mt-24">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                            <i className="fas fa-award"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Erfarna Städexperter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vårt team består av erfarna och pålitliga städexperter som är dedikerade till att leverera högkvalitativ service.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                                            <i className="fas fa-retweet"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Anpassade Tjänster</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi förstår att varje hem är unikt. Våra städare skräddarsyr tjänsterna efter dina specifika behov och önskemål.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                                            <i className="fas fa-fingerprint"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Miljövänliga Produkter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi strävar efter att vara miljövänliga. Våra städare använder endast högkvalitativa, miljövänliga rengöringsprodukter.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap items-center mt-32">
                            <div className="w-full px-4 mr-auto ml-auto">
                                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                    Vad ingår i trappstädning?
                                </h3>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                    Vår trappstädning omfattar en omfattande rengöring av trapphuset och dess olika delar. På varje våningsplan dammtorkar och rengör vi lister och snickerier, sopar eller dammsuger trappan, och moppar golv på samtliga våningsplan.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Lampor och trappräcken behandlas noggrant, och vi ser till att hålla områden som entré, hiss och tvättstuga i bästa skick. I entrén fokuserar vi på dammtorkning, dammsugning eller sopning av golv, och rengöring av entrédörren.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    I hissen tar vi hand om speglar, panel, handtag och golv.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Tvättstugan genomgår en grundlig rengöring av maskiner och ytor, medan föreningslokalen får en omfattande behandling, inklusive dammtorkning, dammsugning eller sopning, samt moppning av golv och rengöring av olika ytor, inklusive kök och toalett.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Våra städexperter utför även tömning och byte av papperskorgar för att säkerställa att alla utrymmen förblir fräscha och välskötta.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
