import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-blueGray-200 pt-8 pb-6 border-mask">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold">Kontakta oss redan idag...</h4>
              <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                Vi svarar 1-2 arbetsdagar.
              </h5>
              {/* <div className="mt-6 lg:mb-0 mb-6">
                <a href="https://instagam.com/" target="_blank">
                  <button
                    className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-instagram"></i>
                  </button>
                </a>
                <a href="https://facebook.com/" target="_blank">
                  <button
                    className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </button>
                </a>
              </div> */}
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                    Useful Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        to="/om-oss"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      >
                        Om oss
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/prislista"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      >
                        Prislista
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/kontakt"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      >
                        Kontakt
                      </Link>
                    </li>
                    {/* <li>
                      <a
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                        href="#"
                        target="_blank"
                      >
                        Google recensioner
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                    Tjänster
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        to="/tjanster/hemstadning"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      >
                        Hemstädning
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/tjanster/storstadning"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      >
                        Storstädning
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/tjanster/flyttstadning"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      >
                        Flyttstädning
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/tjanster/fonsterputs"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      >
                        Fönsterputs
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/tjanster/kontorsstadning"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      >
                        Kontorsstädning
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/tjanster/kontorsstadning#lokalstadning"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      >
                        Lokalstädning
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/tjanster/trappstadning"
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      >
                        Trappstädning
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blueGray-500 font-semibold py-1">
                Copyright © {new Date().getFullYear()} Laki Städ by{" "}
                <a
                  href="https://belikenikola.com"
                  className="text-blueGray-500 hover:text-blueGray-800"
                  target="_blank"
                  rel="noreferrer"
                >
                  BeLikeNikola
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
