import React from "react";

// components

import Navbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footers/Footer"

import backgroundImage from "assets/img/moving-boxes.jpeg"

export default function Flyttstadning() {
    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage:
                                `url(${backgroundImage})`,
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                                <h1 className="text-white font-semibold text-5xl">
                                    Ta hjälp med flyttstädningen
                                </h1>
                                <p className="mt-4 text-lg text-blueGray-200">
                                    Smidiga flyttar börjar med en skinande avslutning. Höj din flyttupplevelse med våra expertstädningstjänster. Stressfria övergångar väntar!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="pb-20 bg-white -mt-24">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                            <i className="fas fa-award"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Erfarna Städexperter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vårt team består av erfarna och pålitliga städexperter som är dedikerade till att leverera högkvalitativ service.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                                            <i className="fas fa-retweet"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Anpassade Tjänster</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi förstår att varje hem är unikt. Våra städare skräddarsyr tjänsterna efter dina specifika behov och önskemål.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                                            <i className="fas fa-fingerprint"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Miljövänliga Produkter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi strävar efter att vara miljövänliga. Våra städare använder endast högkvalitativa, miljövänliga rengöringsprodukter.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap items-center mt-32">
                            <div className="w-full px-4 mr-auto ml-auto">
                                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                    Vad ingår i vår flyttstädning?
                                </h3>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                    Generella moment i alla rum innefattar omfattande städåtgärder, som putsning av fönster och dammsugning med avtorkning av snickerier, lister, dörrar, och fönsterbrädor. In- och utvändig avtorkning av garderober och skåp, dammsugning och avtorkning av element, samt dammtorkning av väggar och eluttag, ingår också. Putsning av speglar, borttagning av aska från kakelugn, avtorkning av ytterdörrar, och dammsugning med våttorkning av golv slutför de generella momenten.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    I köket utförs de generella momenten med tillägg av ut- och invändig rengöring av kyl och frys, spis och ugn, inklusive plåtar och galler. Rengöring av fläkt och fläktfilter, ut- och invändig rengöring av skåp, hyllor, och lådor, samt putsning av diskho och blandare, ingår i köksrutinen.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Badrumsstädningen innefattar generella åtgärder, såsom rengöring av väggar och golv, putsning av blandare och synliga rör. Avfettning och avkalkning av väggar och fogar, rengöring av golvbrunn, och utvändig rengöring av vitvaror kompletterar badrumsstädningen.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    I hallen genomförs generella moment, inklusive dammtorkning av ledstång, räcke, och spjälor i trappan. Avtorkning av hatthylla och proppskåp avslutar städningen i hallområdet.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
