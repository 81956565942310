import React, { useState, useRef } from "react";
import { sendEmail } from "../config/contact";

// components

import Navbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footers/Footer"

import backgroundImage from "assets/img/sweden-city.jpeg"

export default function Kontakt() {

    const [selectedService, setSelectedService] = useState('');
    const [showSquareMeters, setShowSquareMeters] = useState(false);
    const [showNumberOfRooms, setShowNumberOfRooms] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [formData, setFormData] = useState({});
    const [formStatus, setFormStatus] = useState(null);

    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const messageRef = useRef(null);
    const squareMetersRef = useRef(null);
    const numberOfRoomsRef = useRef(null);

    const servicesWithSquareMeters = ['hemstadning', 'storstadning', 'flyttstadning', 'kontorsstadning', 'lokalstadning'];
    const servicesWithNumberOfRooms = ['fonsterputs'];

    const handleServiceChange = (event) => {
        const selectedValue = event.target.value;

        setShowSquareMeters(servicesWithSquareMeters.includes(selectedValue));
        setShowNumberOfRooms(servicesWithNumberOfRooms.includes(selectedValue));

        setSelectedService(selectedValue);
    };

    const handlePhoneNumberChange = (event) => {
        const number = event.target.value;
        setPhoneNumber(number);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const message = messageRef.current.value;
        const squareMeters = squareMetersRef.current ? squareMetersRef.current.value : null;
        const numberOfRooms = numberOfRoomsRef.current ? numberOfRoomsRef.current.value : null;

        const updatedFormData = {
            name,
            email,
            message,
            phoneNumber,
            selectedService,
            squareMeters,
            numberOfRooms,
        };

        setFormData(updatedFormData);

        sendEmail(updatedFormData)
            .then(() => {
                setFormStatus({ type: 'success', message: 'Tack så mycket! Ditt meddelande har skickats.' });

                nameRef.current.value = '';
                emailRef.current.value = '';
                messageRef.current.value = '';
                if (squareMetersRef.current) squareMetersRef.current.value = '';
                if (numberOfRoomsRef.current) numberOfRoomsRef.current.value = '';
            })
            .catch((error) => {
                setFormStatus({ type: 'error', message: 'Något gick fel. Vänligen försök igen.' });
            });
    };

    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage:
                                `url(${backgroundImage})`,
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                                <h1 className="text-white font-semibold text-5xl">
                                    Kontakta oss
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px border-mask-dark"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-blueGray-200 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                </div>

                <section className="pb-20 relative block bg-blueGray-800 ">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-blueGray-800 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
                        <div className="flex flex-wrap mt-12 justify-center -mt-20">
                            <div className="w-full lg:w-3/12 px-4 text-center">
                                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                    <i className="fas fa-envelope-open text-xl"></i>

                                </div>
                                <h6 className="text-xl mt-5 font-semibold text-white">
                                    E-post
                                </h6>
                                <p className="mt-2 mb-4 text-blueGray-400">
                                    <a href="mailto:info@lakistad.se">info@lakistad.se</a><br />
                                    <a href="mailto:lakistad@yahoo.com">lakistad@yahoo.com</a>
                                </p>
                            </div>
                            <div className="w-full lg:w-3/12 px-4 text-center">
                                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                    <i className="fas fa-mobile text-xl"></i>
                                </div>
                                <h5 className="text-xl mt-5 font-semibold text-white">
                                    Ring oss
                                </h5>
                                <p className="mt-2 mb-4 text-blueGray-400">
                                    <a href="tel:+46704521778">070 452 17 78</a><br />
                                    <a href="tel:+46707678684">070 767 86 64</a>
                                </p>
                            </div>
                            <div className="w-full lg:w-3/12 px-4 text-center">
                                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                    <i className="fas fa-star text-xl"></i>
                                </div>
                                <h5 className="text-xl mt-5 font-semibold text-white">
                                    Stad
                                </h5>
                                <p className="mt-2 mb-4 text-blueGray-400">
                                    Trollhättan, Lila edet, Vänersborg, Uddevalla
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
                    <div className="container mx-auto px-4 mt-6">
                        <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                                    <div className="flex-auto p-5 lg:p-10">
                                        <h4 className="text-2xl font-semibold">Boka tid</h4>
                                        <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                                            Fyll i formuläret med dina kontaktuppgifter, välj vilken tjänst du vill boka och storlek på ditt hem eller kontor i kvadratmeter. Beskriv hur vi kan hjälpa dig, så återkommer vi till dig så snart som möjligt.
                                        </p>
                                        <div className="relative w-full mb-3 mt-8">
                                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="full-name">
                                                Namn
                                            </label>
                                            <input
                                                type="text"
                                                ref={nameRef}
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Fullständiga namn"
                                            />
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email">
                                                E-post
                                            </label>
                                            <input
                                                type="email"
                                                ref={emailRef}
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Dit e-post adress"
                                            />
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="phone-number">
                                                Telefonnummer
                                            </label>
                                            <input
                                                type="tel"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Ditt telefonnummer"
                                                id="phone-number"
                                                value={phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                            />
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="message">
                                                Meddelande
                                            </label>
                                            <textarea
                                                rows="4"
                                                cols="80"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                placeholder="Skriv ett meddelande..."
                                                ref={messageRef}
                                            />
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="service-option">
                                                Välj tjänst du vill ha
                                            </label>
                                            <select
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                id="service-option"
                                                onChange={handleServiceChange}
                                                value={selectedService}
                                            >
                                                <option value="" disabled hidden>
                                                    Välj tjänsten
                                                </option>
                                                <option value="hemstadning">Hemstädning</option>
                                                <option value="storstadning">Storstädning</option>
                                                <option value="flyttstadning">Flyttstädning</option>
                                                <option value="kontorsstadning">Kontorsstädning</option>
                                                <option value="lokalstadning">Lokalstädning</option>
                                                <option value="trappstadning">Trappstädning</option>
                                                <option value="fonsterputs">Fönsterputs</option>
                                            </select>
                                        </div>

                                        {showSquareMeters && (
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="square-meters">
                                                    Square Meters (kvm)
                                                </label>
                                                <input
                                                    type="number"
                                                    ref={squareMetersRef}
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    placeholder="Enter square meters"
                                                    id="square-meters"
                                                />
                                            </div>
                                        )}

                                        {showNumberOfRooms && (
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="number-of-rooms">
                                                    Antal rum
                                                </label>
                                                <input
                                                    type="number"
                                                    ref={numberOfRoomsRef}
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    placeholder="Skriv antal rum"
                                                    id="number-of-rooms"
                                                />
                                            </div>
                                        )}

                                        <div className="text-center mt-6">
                                            {formStatus && (
                                                <p className={formStatus.type === 'success' ? 'text-green-600' : 'text-red-600'}>
                                                    {formStatus.message}
                                                </p>
                                            )}
                                            <button
                                                type="submit"
                                                className="mt-6 bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                onClick={handleFormSubmit}
                                            >
                                                Skicka meddelande
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
