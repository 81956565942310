import { ses } from "./awsConfig";

export const sendEmail = async (req, res) => {
  const { name, email, phoneNumber, message, selectedService, squareMeters, numberOfRooms } = req;

  const params = {
    Destination: {
      ToAddresses: ['info@lakistad.se'],
    },
    Message: {
      Body: {
        Text: {
          Data: `Namn: ${name}\nEpost: ${email}\nTelefonnummer: ${phoneNumber}\nMessage: ${message}\nVald tjänst: ${selectedService}\n${squareMeters ? `Square Meters: ${squareMeters}\n` : ''
            }${numberOfRooms ? `Antal rum: ${numberOfRooms}\n` : ''}`,
        },
      },
      Subject: { Data: 'Hemsida >>' },
    },
    Source: 'info@lakistad.se',
  };

  try {
    await ses.sendEmail(params).promise();
  } catch (error) {
    console.error('Error sending email:', error);
  }
};