/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px green-hero">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="sm:pt-0">
              <h2 className="font-semibold text-4xl text-blueGray-600">
                Professionell städning i Trollhättan med Laki Städ
              </h2>
              <h3>
                Vi tar hand om dig och låter ditt hem eller kontor stråla!
              </h3>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Välkommen till <strong>Laki Städ</strong>, din pålitliga partner för skräddarsydda städtjänster. Vi kombinerar lång erfarenhet och omsorg om detaljer för att skapa en ren och fräsch miljö åt dig. Utforska våra professionella städtjänster nedan och boka din tid redan idag för en skinande ren hem- eller arbetsplats. Vi är här för att göra skillnad och skapa en bekymmersfri miljö.
              </p>
              <div className="mt-12 flex justify-center md:justify-start ">
                <Link
                  to="/"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Våra tjänster
                </Link>
                <Link
                  to="/kontakt"
                  className="ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Boka Tid
                </Link>
              </div>
            </div>
          </div>
        </div>

        <img
          className="hidden lg:flex absolute top-0 b-auto right-0 -mt-48 sm:mt-0 max-h-860px"
          src={require("assets/img/clean-home-hero.png").default}
          alt="..."
        />
      </section>

      <section className="pb-40 relative bg-blueGray-100">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-4/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg bg-lightBlue-500">
                <img
                  alt="..."
                  src={require("assets/img/vacuum-cleaner.jpeg").default}
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-lightBlue-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-white">
                    Skinande Rymder: Förstklassiga Hemstädning
                  </h4>
                  <p className="text-md font-light mt-2 text-white">
                    Oavsett om du behöver regelbunden underhållsstädning eller en djuprengöring inför speciella tillfällen, kan våra hemstädningstjänster anpassas för att möta dina behov. Ge ditt hem den kärlek det förtjänar med våra pålitliga och professionella städare.
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <h6 className="text-xl mb-1 font-semibold">
                        Storstädning
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        Det är en utmärkt möjlighet att återställa fräschören i utrymmet och skapa en hälsosam och trivsam miljö. Storstädningen inkluderar att rengöra väggar, fönster, och andra mindre tillgängliga områden.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <h6 className="text-xl mb-1 font-semibold">
                        Flyttstädning
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        Med fokus på detaljer och högkvalitativ rengöring, gör vår flyttstädning inte bara din nya bostad skinande ren utan ger också ett positivt intryck för nästa ägare.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <h6 className="text-xl mb-1 font-semibold">
                        Kontorsstädning
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        Vår professionella kontorsstädning skapar en ren och välkomnande arbetsmiljö, främjar produktivitet och ger ditt företag en skräddarsydd lösning för hållbar renlighet.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <h6 className="text-xl mb-1 font-semibold">
                        Trappstädning
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        Med våra trappstädningstjänster lyfter vi fram entrén till ditt boende eller företag genom att säkerställa en ren och inbjudande trappuppgång, vilket skapar en positiv första intryck och trivsel.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap mt-24">
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
              <h3 className="text-3xl font-semibold">
                Nöjda kunder och toppservice
              </h3>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Ett starkt samband mellan en bra städtjänst och nöjda kunder är grundläggande för att skapa en trivsam och hälsosam miljö. Hos Laki Städ förstår vi vikten av denna relation och strävar efter att erbjuda inte bara enastående städtjänster utan även en vänlig och respektfull kundrelation.
              </p>
              <ul className="list-none mt-6">
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-blueGray-500">
                        Vi tror att en ren och organiserad omgivning inte bara påverkar trivseln utan även bidrar till våra kunders övergripande välbefinnande.
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-blueGray-500">
                        Med vår bas i Trollhättan erbjuder Laki Städ toppservice för både privatpersoner och företagskunder.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-lg"
                src={require("assets/img/customer-review.png").default}
              />
            </div>
          </div>
        </div>

        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <h2 className="font-semibold text-4xl">Städgaranti</h2>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
              Vi erbjuder en städgaranti, vilken innebär att om det är något du skulle vara missnöjd över och rapporterar detta inom 3 dagar så kommer städhjälpen tillbaka och löser det – utan extra kostnad förstås!
            </p>
          </div>
        </div>

        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <h2 className="font-semibold text-4xl">Varför välja våra städtjänster?</h2>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
              Vår dedikerade personal arbetar hårt för att säkerställa att varje klient upplever en personlig och professionell städupplevelse, vilket i sin tur leder till nöjda och glada kunder.
            </p>
          </div>
        </div>
      </section>

      <section className="block relative z-1 bg-blueGray-700">
        <div className="container mx-auto">
          <div className="justify-center flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4  -mt-24">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    Fönsterputs
                  </h5>
                  <Link to="/tjanster/fonsterputs">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/window-cleaning.jpeg").default}
                      />
                    </div>
                  </Link>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    Kontor- och lokalstädning
                  </h5>
                  <Link to="/tjanster/kontorsstadning">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/office-cleaning.jpeg").default}
                      />
                    </div>
                  </Link>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    Visningstädning
                  </h5>
                  <Link to="/tjanster/storstadning">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/bathroom-cleaning.jpeg").default}
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-blueGray-700 overflow-hidden">
        <div className="container mx-auto">
          <div className="-mt-20 flex flex-wrap justify-center">
            <img
              alt="..."
              src={require("assets/img/green-spring-leaves.png").default}
            />
          </div>
        </div>
      </section>

      <section className="pb-16 bg-blueGray-700 relative pt-32">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10 mx-4">
            <div className="w-full text-center lg:w-8/12">
              <p className="text-4xl text-center">
                <span role="img" aria-label="love">
                  😍
                </span>
              </p>
              <h3 className="font-semibold text-3xl">
                Hur får man bästa städtjänsten i Trollhättan-området?
              </h3>
              <p className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <strong>Boka Din Städning:</strong><br /> Enkelt boka din städning online eller kontakta vår kundtjänst för personlig hjälp.
                <br />
                <strong>Anpassa Ditt Paket:</strong><br /> Välj de städtjänster och frekvens som passar bäst för ditt projekt.
                <br />
                <strong>Njut av Ett Rent Hem:</strong><br /> Vår professionella personal tar hand om städningen medan du fokuserar på ditt fantastiska projekt.
              </p>
              <div className="sm:block flex flex-col mt-10">
                <Link
                  to="/kontakt"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Boka tid
                </Link>
                {/* <a
                  href="#"
                  target="_blank"
                  className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg"
                >
                  <span>Lämna din recension på </span>
                  <i className="fab fa-google text-xs mr-1"></i>
                </a> */}
              </div>
              <div className="text-center mt-16"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
