import React from "react";

// components

import Navbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footers/Footer"

import backgroundImage from "assets/img/kids-cleaning.jpeg"

export default function OmOss() {
    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage:
                                `url(${backgroundImage})`,
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                                <h1 className="text-white font-semibold text-5xl">
                                    Om Laki Städ
                                </h1>
                                <p className="mt-4 text-lg text-blueGray-200">
                                    Låt oss ta hand om städningen istället för dig i Trollhättan, Lila edet, Vänersborg och Uddevalla...
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="pb-20 bg-white -mt-24">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                            <i className="fas fa-award"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Erfarna Städexperter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vårt team består av erfarna och pålitliga städexperter som är dedikerade till att leverera högkvalitativ service.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                                            <i className="fas fa-retweet"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Anpassade Tjänster</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi förstår att varje hem är unikt. Våra städare skräddarsyr tjänsterna efter dina specifika behov och önskemål.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                                            <i className="fas fa-fingerprint"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Miljövänliga Produkter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi strävar efter att vara miljövänliga. Våra städare använder endast högkvalitativa, miljövänliga rengöringsprodukter.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container mx-auto px-4 mt-24">
                            <div className="items-center flex flex-wrap">
                                <div className="w-full md:w-4/12 ml-auto mr-auto p-4">
                                    <img
                                        alt="..."
                                        className="max-w-full rounded-lg shadow-lg"
                                        src={require("assets/img/cleaning-products.jpeg").default}
                                    />
                                </div>
                                <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                                    <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
                                        <i className="fas fa-rocket text-xl"></i>
                                    </div>
                                    <h3 className="text-3xl font-semibold">Välkommen till Laki Städ</h3>
                                    <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                        Din pålitliga städpartner i Trollhättan, Lilla Edet, Vänersborg och Uddevalla...
                                    </p>
                                    <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                        Hos Laki Städ för vi in en ny ansats till hem- och kontorsstädning och levererar noggranna tjänster skräddarsydda för både privata bostäder och företagsmiljöer.
                                    </p>
                                    <ul className="list-none mt-6">
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-arrow-right"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        Professionalism och en passion för renlighet
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-arrow-right"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        Tjänster anpassade för dig
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-arrow-right"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        Privata och företagskunder
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
