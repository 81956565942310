import React from "react";

// components

import Navbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footers/Footer"

import backgroundImage from "assets/img/big-cleaning.jpeg"

export default function Storstadning() {
    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage:
                                `url(${backgroundImage})`,
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                                <h1 className="text-white font-semibold text-5xl">
                                    Dags för en storstädning?
                                </h1>
                                <p className="mt-4 text-lg text-blueGray-200">
                                    Ibland behöver hemmet en ordentlig genomgång, ner till varenda liten list. Vi ser till att det blir riktigt rent helt enkelt. Här är vad som ingår i en storstädning.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="pb-20 bg-white -mt-24">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                            <i className="fas fa-award"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Erfarna Städexperter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vårt team består av erfarna och pålitliga städexperter som är dedikerade till att leverera högkvalitativ service.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                                            <i className="fas fa-retweet"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Anpassade Tjänster</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi förstår att varje hem är unikt. Våra städare skräddarsyr tjänsterna efter dina specifika behov och önskemål.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                                            <i className="fas fa-fingerprint"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Miljövänliga Produkter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi strävar efter att vara miljövänliga. Våra städare använder endast högkvalitativa, miljövänliga rengöringsprodukter.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap items-center mt-32">
                            <div className="w-full px-4 mr-auto ml-auto">
                                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                    Vad ingår i vår storstädning?
                                </h3>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                    Generella moment i alla rum innefattar omfattande rengöringsåtgärder, såsom dammtorkning av väggar, snickerier, och prydnadssaker. Dammsugning och avtorkning utförs på snickerier, element, och fria ytor, inklusive ovanpå garderober och skåp. Eluttag, kontakter, och stoppade möbler dammtorkas, och även elektronik torrdammas. Det inkluderar även putsning av speglar, tömning av papperskorgar, dammsugning och våttorkning av golv.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    I köket utökas de generella momenten med ytterligare åtgärder, såsom rengöring av fläkt och fläktfilter, ovanpå skåp och hyllor, samt ut- och invändig avtorkning av köksluckor. Rengöring av kyl och frys, kakel/stänkskydd, mikrovågsugn, och diskmaskin inkluderas också. Det innefattar även avtorkning av det som står framme, som kaffemaskin och kryddor.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    I sovrummet innebär generella moment bland annat bäddning av sängar och eventuellt byte av sängkläder om rena lakan är tillgängliga. Dammtorkning eller dammsugning av sänggavel/-karm utförs.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    I hallen inkluderar generella moment dammtorkning av ledstång, räcke, och spjälor i trappan. Skoställ och hatthylla dammsugs och avtorkas, och även proppskåp avtorkas.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Utöver dessa generella moment erbjuds tilläggstjänster som fönsterputs, karmtvätt, invändig ugnsrengöring, rengöring av kylskåp och frys, köksskåp och lådor, balkong- och garagestädning, putsning av kristallkrona, putsning av mässing eller silver, skinnvård av soffor, och strykning.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
