import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/custom.css";

import OmOss from "views/OmOss"
import Prislista from "views/Prislista"
import Kontakt from "views/Kontakt"
import Hemstading from "views/tjanster/Hemstadining"
import Flyttstadning from "views/tjanster/Flyttstadning"
import Storstadning from "views/tjanster/Storstadning"
import Kontorsstadning from "views/tjanster/Kontorsstadning"
import Trappstadning from "views/tjanster/Trappstadning"
import Fonsterputs from "views/tjanster/Fonsterputs"
import Index from "views/Index.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/om-oss" exact component={OmOss} />
      <Route path="/prislista" exact component={Prislista} />
      <Route path="/kontakt" exact component={Kontakt} />
      <Route path="/tjanster/hemstadning" exact component={Hemstading} />
      <Route path="/tjanster/storstadning" exact component={Storstadning} />
      <Route path="/tjanster/flyttstadning" exact component={Flyttstadning} />
      <Route path="/tjanster/kontorsstadning" exact component={Kontorsstadning} />
      <Route path="/tjanster/trappstadning" exact component={Trappstadning} />
      <Route path="/tjanster/fonsterputs" exact component={Fonsterputs} />
      <Route path="/" exact component={Index} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
