import React from "react";

// components

import Navbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footers/Footer"

import backgroundImage from "assets/img/window-cleaning.jpeg"

export default function Fonsterputs() {
    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage:
                                `url(${backgroundImage})`,
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                                <h1 className="text-white font-semibold text-5xl">
                                    Fönsterputs
                                </h1>
                                <p className="mt-4 text-lg text-blueGray-200">
                                    Få ditt hem att stråla med skinande fönster! Vår fönsterputsning ger ljusare utsikter och en klarare vardag. Boka nu för en klarare framtid!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="pb-20 bg-white -mt-24">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                            <i className="fas fa-award"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Erfarna Städexperter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vårt team består av erfarna och pålitliga städexperter som är dedikerade till att leverera högkvalitativ service.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                                            <i className="fas fa-retweet"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Anpassade Tjänster</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi förstår att varje hem är unikt. Våra städare skräddarsyr tjänsterna efter dina specifika behov och önskemål.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                                            <i className="fas fa-fingerprint"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Miljövänliga Produkter</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Vi strävar efter att vara miljövänliga. Våra städare använder endast högkvalitativa, miljövänliga rengöringsprodukter.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap items-center mt-32">
                            <div className="w-full px-4 mr-auto ml-auto">
                                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                    Om vår fönsterputsning:
                                </h3>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                    Vi utför putsning på alla sidor av fönsterrutorna och även takfönster om de kan vridas runt sin axel. Vi tillhandahåller all nödvändig fönsterputsutrustning och putsmedel. Om du inte har en lämplig stege kan vi erbjuda detta som tillval mot en extra kostnad, vilket du kan ange vid bokning. För optimalt resultat rekommenderar vi även karmtvätt, där vi rengör fönstrets bågar och karmar. Om du önskar att få fönsterkarmarna rengjorda, vänligen meddela oss vid bokning, och det kan komma att medföra en extra kostnad.
                                </p>
                                <h4 className="text-xl mb-2 font-semibold leading-normal">
                                    Vad som räknas som ett fönster:
                                </h4>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Varje fönsterbåge eller luft betraktas som ett fönster. Till exempel, om ditt fönster har en fönsterbåge kallas det för ett 1-luftfönster. Om det har två fönsterbågar, oavsett riktning (höger eller vänster), räknas det som två fönster och benämns som ett 2-luftfönster.
                                </p>
                                <h4 className="text-xl mb-2 font-semibold leading-normal">
                                    Visste du att:
                                </h4>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Vi kan utföra putsning även om det finns skador på fönstret, men vi vill bli informerade om detta innan vi kommer. Var vänlig och meddela oss om det finns repor, trasiga lås eller persienner.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                                    Vi utför fönsterputsning även under regn och snö (ned till -15 grader Celsius).<br />
                                    Våra fönsterputsare är försäkrade, och vi har ansvarsförsäkring som täcker eventuella skador.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
